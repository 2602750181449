import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TaskStatus } from '@conversations/tasks/tasks.model';
import { TranslateModule } from '@ngx-translate/core';

const backgroundColors: Partial<Record<TaskStatus, string>> = {
  [TaskStatus.Completed]: 'var(--comp-status-chip-color-completed-bg, #D6F9EA)',
  [TaskStatus.NotStarted]: 'var(--comp-status-chip-color-not-started-bg, #F9E8C0)',
  [TaskStatus.InProgress]: 'var(--comp-status-chip-color-in-progress-bg, #E3F4FC)',
  [TaskStatus.WaitingForConnection]: 'var(--comp-status-chip-color-no-connection-bg, #FBE4E9)',
  [TaskStatus.WaitingForApproval]: 'var(--comp-status-chip-color-not-started-bg, #F9E8C0)',
};

const foregroundColors: Partial<Record<TaskStatus, string>> = {
  [TaskStatus.Completed]: 'var(--comp-status-chip-color-completed-fg, #08A262)',
  [TaskStatus.NotStarted]: 'var(--comp-status-chip-color-not-started-fg, #725616)',
  [TaskStatus.InProgress]: 'var(--comp-status-chip-color-in-progress-fg, #04689A)',
  [TaskStatus.WaitingForConnection]: 'var(--comp-status-chip-color-no-connection-fg, #AB2345)',
  [TaskStatus.WaitingForApproval]: 'var(--comp-status-chip-color-not-started-fg, #725616)',
};

const defaultBackgroundColor = 'var(--comp-status-chip-color-closed-bg, #DCDFE3)';
const defaultForegroundColor = 'var(--comp-status-chip-color-closed-fg, #5E6A75)';

@Component({
  selector: 'cc-task-status-chip',
  standalone: true,
  templateUrl: './task-status-chip.component.html',
  styleUrls: ['./task-status-chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule],
})
export class TaskStatusChipComponent {
  @Input()
  status: TaskStatus;

  protected readonly TaskStatus = TaskStatus;

  get backgroundColor(): string {
    return backgroundColors[this.status] ?? defaultBackgroundColor;
  }

  get foregroundColor(): string {
    return foregroundColors[this.status] ?? defaultForegroundColor;
  }
}
